<template>
  <div id="about">
    <div class="about-header">
      <div class="about-header-top">
        <div class="about-header-top-l" @click="onMenu"></div>
        <div class="about-header-top-r">客服热线：400 110 1500</div>
      </div>
      <div class="about-header-top-img">
        <div class="about-top-jrmc"></div>
        <div class="about-top-text">超多车型 放心透明 购买方便 省钱省心</div>
      </div>
    </div>
    <div class="about-main">
      <div class="about-main-img1">
        <div class="main-gywm"></div>
      </div>
      <div class="about-main-img2">
        <div class="img2-l"></div>
        <div class="img2-r"></div>
      </div>
      <div class="about-main-text-wrap">
        <div class="about-main-text" v-html="mainText"></div>
      </div>
    </div>
    <div class="about-map">
      <div class="about-map-lxwm">
        <img
          src="./img/map-lxwm.png"
          alt="map-lxwm"
          style="width: 131px;height: 41px"
        />
      </div>
      <!--   地图   -->
      <!--      <div class="about-map-wrap">-->
      <!--        <div id="map" ref="map"></div>-->
      <!--      </div>-->

      <div class="about-map-wrap">
        <div id="map" ref="map"></div>
      </div>

      <div class="about-address-wrap">
        <div class="about-address-box">
          <div class="about-address" v-html="mapAddress"></div>
        </div>
      </div>
    </div>
    <div class="about-footer">
      <div class="about-footer-text" v-html="footerTxt"></div>
    </div>
    <!--  遮罩层  -->
    <over-lay v-if="showOver" @handleOverShow="updateShow2"></over-lay>

    <!--    <van-popup-->
    <!--        v-model="overShow2"-->
    <!--        position="left"-->
    <!--        closeable-->
    <!--        close-icon="close"-->
    <!--        :style="{ height: '100%',width:'100%',backgroundColor:'#35234B' }"-->
    <!--    >-->
    <!--      <div class="overIcon"></div>-->
    <!--      <div class="over-txt"-->
    <!--           :key="index"-->
    <!--           v-for="(item,index) in textArr"-->
    <!--           :class="{over_txt_active:isIndex1 === index}"-->
    <!--           @click="onClickOverAbout(index)"-->
    <!--      >{{ item.txt }}</div>-->
    <!--      <div class="overlay-footer">-->
    <!--        <img src="./img/overlay-footer.png" alt="overlay-footer" style="width: 160px;height: 51px">-->
    <!--      </div>-->
    <!--    </van-popup>-->
  </div>
</template>

<script>
import { Popup } from "vant";
import overLay from "../../components/overLay/overLay";

export default {
  name: "about",
  components: {
    [Popup.name]: Popup,
    overLay,
  },
  data() {
    return {
      mainText:
        "申科易购大数据集团创办于1995年2月5日，作为目前全球新能源汽车新零售集团中的龙头企业，" +
        "同时也是一家多品类商务公司，经营商品涵盖汽车电子商务、新能源汽车新零售、消费电子、SAAS系统、金融服务、" +
        "百货、线下实体门店200余家。<br/>" +
        "“今日买车”作为首个全流程一站式线上购车服务平台，秉承着「社会信任」「顾客首选」「员工热爱」「股东满意」的价值观；" +
        "肩负着“让天下没有难买的车”的使命，奔赴「我们从不生产车，我们只是车的搬运工」的美好愿景！" +
        "以线上线下的融合发展引领汽车新零售模式新趋势，率先打造汽车行业内生态链闭环全交易的电子商务平台。",
      mapAddress:
        "地址：上海市黄浦区中山南路505号老码头13号楼 <br/>" +
        "客服热线：400 110 1500 <br/>" +
        "客服邮箱：service@cencobuy.com",

    
      footerTxt:
        "今日买车.贾维斯汽车有限公司<br/>" +
        "Cenco Buy Group Co.,Ltd<br/>" +
        "沪ICP备2021026346号-1<br>" +
        "2020-2021 贾维斯汽车有限公司版权所有",
      // overShow2:false,//遮罩层默认不显示
      textArr: [
        { txt: "首页", index: 1 },
        { txt: "关于我们", index: 2 },
      ],
      // isIndex1:0,//默认首页字体
      // mapPoint:require('./img/mapPoint.gif'),
      // mapPoint:require('./img/tubiao.gif'),
      // mapPoint:require('./img/mapPoint2.gif'),
      mapPoint: require("./img/mapPoint4.png"),

      //蒙层组件是否显示
      showOver: false,
    };
  },
  created() {},
  mounted() {
    this.map();
    if (this.$route.path == "/about") {
      this.isIndex1 = 1;
    }
  },
  methods: {
    //点击图标菜单显示遮罩层
    onMenu() {
      // this.overShow2 = true
      this.showOver = true;
    },
    //
    updateShow2() {
      this.showOver = false;
    },

    //2021.4.8改动ß
    // onClickOverAbout(index) {
    //   this.isIndex1 = index
    //   console.log(this.isIndex1);
    //   if( index == 0){
    //     this.$router.push({path:'home'})
    //   }
    // },
    // //点击字体事件
    // overChange(index){
    //   this.isIndex1 = index
    //   console.log(this.isIndex1);
    //   this.overShow2 = false
    //   if( index == 0 && this.$route.path == '/home'){
    //     return
    //   }else if(index ==  0){
    //     this.$router.push({path:'/home'})
    //   }
    //   if( index == 1 && this.$route.path == '/about'){
    //     return
    //   }else if(index ==  1){
    //     this.$router.push({path:'/about'})
    //   }
    // },
    // updateShow(val){
    //   this.overShow = val
    // },

    map() {
      let map = new BMap.Map(this.$refs.map); // 创建地图实例
      let point = new BMap.Point(121.512157, 31.224537); // 创建点坐标
      map.centerAndZoom(point, 15); // 初始化地图，设置中心点坐标和地图级别
      map.clearOverlays();
      map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      let myicon = new BMap.Icon(this.mapPoint, new BMap.Size(30, 30), {
        //设置标注点
        anchor: new BMap.Size(20, 20),
      });
      let marker = new BMap.Marker(point, { icon: myicon }); // 创建标注并添加标注点
      map.addOverlay(marker);
      map.addControl(new BMap.NavigationControl());
    },
  },
};
</script>

<style scoped lang="less">
@import "./about.less";
@import "../../assets/style/overLay.less";
</style>
